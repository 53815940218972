import $oauth2 from '../services/auth';

export default {
  path: '/logout',
  name: 'logout',
  beforeEnter: (to, from, next) => {
    $oauth2.getMe()
      .then(me => {
        if (!me) {
          throw new Error('User not logged in');
        }

        return me;
      })
      .then(() => $oauth2.logout())
      .then(() => next({ name: 'home', replace: true }))
      .catch(() => next({ name: 'home', replace: true }))
    ;
  },
};
