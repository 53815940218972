<template>
  <div class="layout-modal justify-content-center align-content-center">
    <AppHeader boxed no-link-to-home />
    <div class="page-container d-flex flex-column justify-content-center flex-grow-1">
      <router-view class="page py-5 flex-grow-1" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'layout-modal',
  components: {
    AppHeader: () => import('@/views/components/Layout/Header'),
  },
};

</script>

<style lang="scss">

.page-container {
  .page {
    position: relative;

    @include media-breakpoint-up("md") {
      // padding-top: 10% !important;
      // padding-left: 25%;
    }
  }
}

</style>
