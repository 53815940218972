export default {
  baseUrl: `${process.env.BASE_URL || '/api/v1'}`,
  apiBaseUrl: `${process.env.VUE_APP_API_BASE_URL || '/api/v1'}`,
  STORAGE_KEY: 'webapp',
  tokenKey: 'webapp-token',
  refreshKey: 'webapp-refresh',
  client_id: `${process.env.VUE_APP_CLIENT_ID || 'webapp'}`,
  client_secret: `${process.env.VUE_APP_CLIENT_SECRET || '6df6dddd069c6acc615eb12407b70ce'}`,
  env: `${process.env.NODE_ENV} || 'dev'`,
};
