<template>
  <Loading v-if="isLoading" class="wrapper centered" />
  <component :is="layout" v-else class="wrapper centered" />
</template>

<script>

import Loading from '@/views/components/Layout/Loading';
import checkGuardsMixin from '@/libs/OAuth2/mixins/checkGuards';
import checkGuards from '@/security/checkGuards';

import ModalLayout from '@/views/layouts/LayoutModal';
import CompleteLayout from '@/views/layouts/LayoutComplete';

export default {
  name: 'app-layout',
  mixins: [checkGuardsMixin],
  components: {
    Loading,
  },
  data () {
    return {
      loading: true,
      checkGuards: route => checkGuards(route, this.$oauth2, this.$router.replace),
    };
  },
  computed: {
    isLoading () {
      return this.loading || !this.$route?.name;
    },
    layoutName () {
      return this.$route?.meta?.layout || 'panel';
    },
    layout () {
      if (this.layoutName === 'renderless') {
        return null;
      }

      if (this.layoutName === 'modal') {
        return ModalLayout;
      }

      return CompleteLayout;
    },
  },
  async mounted () {
    await this.$oauth2.getMe();

    this.loading = false;
  },
};

</script>

<style lang="scss">

$min-width: 240px !default;

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-width: $min-width;
}

</style>
