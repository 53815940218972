import $oauth2 from '../libs/OAuth2/services/auth';
import checkGuards from '../security/checkGuards';

export default async (to, from, next) => {
  await $oauth2.getMe();

  if (checkGuards(to, $oauth2, next)) {
    return;
  }

  next();
};
