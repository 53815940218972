<template>
  <AwlLoader :imagePath="require('@/assets/images/logo.svg')" animated fitViewport></AwlLoader>
</template>

<script>

export default {
  components: {
    AwlLoader: () => import('../../../libs/Loader/components/AwlBouncingLoader'),
  },
};

</script>

<style lang="scss">

.awl-loader {
  background: #fff;
}

</style>
