import $axios from '../libs/OAuth2/services/axios';
import md5 from 'md5';

const emailToMd5 = email => md5(email.toString().toLowerCase());
const defaultPerPage = 15;

export default {
  // Uploader
  uploadFile: file => $axios.post('/temporaryFiles', { file }),

  // Change password
  getChangePasswordToken: email => $axios.post(`/users/${emailToMd5(email)}/changePasswordTokens`),
  changePasswordWithToken: (email, token, password) => $axios.post(`/users/${email}/password`, { token, password }),

  // My profile
  getMyProfile: () => $axios.get('/profile'),
  updateMyProfile: user => $axios.put('/profile', user),
  changeMyPassword: (password, newPassword) => $axios.post('/profile/password', { password, newPassword }),

  // Projects
  listProjects: (page = 1, perPage = defaultPerPage, params = {}) => $axios.get('/projects', {
    params: {
      ...params,
      page,
      perPage,
    },
  }),
  createProject: company => $axios.post('/projects', company),
  fetchProjects: () => $axios.get('/projects', {
    params: {
      all: true,
    },
  }),
  fetchProject: id => $axios.get('/projects/' + id),
  removeProject: id => $axios.delete('/projects/' + id),
  updateProject: (id, project) => $axios.put('/projects/' + id, project),
  fetchProjectViseaModule: id => $axios.get('/projects/' + id + '/visea'),
  updateProjectModuleVisea: (id, payload) => $axios.put('/projects/' + id + '/visea', payload),
  createProjectTutorial: (projectId, note) => $axios.post(`/projects/${projectId}/tutorials`, note),
  fetchProjectTutorial: (projectId) => $axios.get(`/projects/${projectId}/tutorials`, {
    params: {
      all: true,
    },
  }),
  removeProjectTutorial: id => $axios.delete('/tutorials/' + id),
  updateProjectTutorial: (id, note) => $axios.put('/tutorials/' + id, note),

  // Modules
  createModule: (projectId, projectModule) => $axios.post(`/projects/${projectId}/modules`, projectModule),
  listModules: (projectId, page = 1, perPage = defaultPerPage, params = {}) => $axios.get(`/projects/${projectId}/modules`, {
    params: {
      ...params,
      page,
      perPage,
    },
  }),
  fetchModule: id => $axios.get('/modules/' + id),
  removeModule: id => $axios.delete('/modules/' + id),
  updateModule: (id, projectModule) => $axios.put('/modules/' + id, projectModule),

  // Points
  createPoint: (moduleId, point) => $axios.post(`/modules/${moduleId}/points`, point),
  listPoints: (moduleId, page = 1, perPage = defaultPerPage, params = {}) => $axios.get(`/modules/${moduleId}/points`, {
    params: {
      ...params,
      page,
      perPage,
    },
  }),
  fetchPoint: id => $axios.get('/points/' + id),
  removePoint: id => $axios.delete('/points/' + id),
  updatePoint: (id, point) => $axios.put('/points/' + id, point),
  updatePointCoords: (id, x, y) => $axios.post('/points/' + id + '/coords', { x, y }),
  fetchPointContours: id => $axios.get('/points/' + id + '/contours'),
  updatePointContours: (id, payload) => $axios.post('/points/' + id + '/contours', payload),

  // Notes
  createNote: (pointId, note) => $axios.post(`/points/${pointId}/notes`, note),
  fetchNotes: (pointId) => $axios.get(`/points/${pointId}/notes`, {
    params: {
      all: true,
    },
  }),
  removeNote: id => $axios.delete('/notes/' + id),
  updateNote: (id, note) => $axios.put('/notes/' + id, note),

  // Users
  updateUser: (id, user) => $axios.put('/users/' + id, user),

  // Operators
  listOperators: (page = 1, perPage = defaultPerPage, params = {}) => $axios.get('/users', {
    params: {
      ...params,
      page,
      perPage,
    },
  }),
  createOperator: user => $axios.post('/users', user),
  fetchOperator: id => $axios.get('/users/' + id),
  updateOperator: (id, user) => $axios.put('/users/' + id, user),
};
