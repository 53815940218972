import logoutRoute from '@/libs/OAuth2/routes/logoutRoute';
import catchallToRootRoute from '@/libs/Router/routes/catchallToRootRoute';

const onlyGuests = true;
const onlyLoggedIn = true;

export default [
  /* PUBLIC */
  {
    path: '/',
    name: 'home',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/pages/Public/Login'),
    meta: {
      layout: 'modal',
      guards: { onlyGuests },
    },
  },
  {
    path: '/password-reset/:email?',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "login" */ '@/views/pages/Public/PasswordReset'),
    meta: {
      layout: 'modal',
    },
  },
  {
    path: '/password-reset/:email/change-password/:token',
    name: 'password-reset-change-password',
    component: () => import(/* webpackChunkName: "login" */ '@/views/pages/Public/ResetPasswordChange'),
    meta: {
      layout: 'modal',
    },
  },

  /* USER PROFILE */
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/pages/MyProfile/ShowMyProfile'),
    meta: {
      guards: { onlyLoggedIn },
    },
  },
  {
    path: '/profile/edit',
    name: 'profile.edit',
    component: () => import('@/views/pages/MyProfile/EditMyProfile'),
    meta: {
      guards: { onlyLoggedIn },
    },
  },
  {
    path: '/profile/password',
    name: 'change-password',
    component: () => import('@/views/pages/MyProfile/ChangeMyPassword'),
    meta: {
      guards: { onlyLoggedIn },
    },
  },

  /* PROJECTS */
  {
    path: '/projects',
    component: () => import('@/libs/Router/components/InnerRouter'),
    meta: {
      guards: { roles: ['admin', 'operator'] },
    },
    children: [
      {
        path: '',
        name: 'projects.list',
        component: () => import('@/views/pages/Project/ListProjects'),
      },
      {
        path: 'new',
        name: 'projects.new',
        component: () => import('@/views/pages/Project/CreateProject'),
        meta: {
          guards: { roles: ['admin'] },
        },
      },
      {
        path: ':id',
        component: () => import('@/views/pages/Project/ProjectFrame'),
        children: [
          {
            path: 'edit',
            name: 'projects.edit',
            component: () => import('@/views/pages/Project/EditProject'),
            meta: {
              guards: { roles: ['admin'] },
            },
          },
          {
            path: 'visea',
            name: 'projects.visea',
            component: () => import('@/views/pages/Project/UpdateProjectModuleVisea'),
            meta: {
              guards: { roles: ['admin'] },
            },
          },
          {
            path: 'visea-graph',
            name: 'projects.visea.graph',
            component: () => import('@/views/pages/Project/UpdateGraphProjectModuleVisea'),
            meta: {
              guards: { roles: ['admin'] },
            },
          },
          {
            path: 'tutorial',
            name: 'projects.tutorial',
            component: () => import('@/views/pages/Project/ProjectTutorial'),
            meta: {
              guards: { roles: ['admin'] },
            },
          },
          {
            path: '',
            name: 'projects.show',
            component: () => import('@/views/pages/Project/ShowProject'),
          },

          /* MODULES */
          {
            path: 'modules',
            component: () => import('@/libs/Router/components/InnerRouter'),
            meta: {
              guards: { roles: ['admin', 'operator'] },
            },
            children: [
              {
                path: 'new',
                name: 'modules.new',
                component: () => import('@/views/pages/Project/Module/CreateModule'),
              },
              {
                path: ':moduleId',
                component: () => import('@/views/pages/Project/Module/ModuleFrame'),
                children: [
                  {
                    path: 'edit',
                    name: 'modules.edit',
                    component: () => import('@/views/pages/Project/Module/EditModule'),
                  },
                  {
                    path: '',
                    name: 'modules.show',
                    component: () => import('@/views/pages/Project/Module/ShowModule'),
                  },

                  /* POINTS */
                  {
                    path: 'points',
                    component: () => import('@/libs/Router/components/InnerRouter'),
                    meta: {
                      guards: { roles: ['admin', 'operator'] },
                    },
                    children: [
                      {
                        path: 'new',
                        name: 'points.new',
                        component: () => import('@/views/pages/Project/Module/Point/CreatePoint'),
                      },

                      {
                        path: ':pointId',
                        component: () => import('@/views/pages/Project/Module/Point/PointFrame'),
                        children: [
                          {
                            path: 'edit',
                            name: 'points.edit',
                            component: () => import('@/views/pages/Project/Module/Point/EditPoint'),
                          },
                          {
                            path: 'pick',
                            name: 'points.pick',
                            component: () => import('@/views/pages/Project/Module/Point/PickPoint'),
                          },
                          {
                            path: 'contours',
                            name: 'points.contours',
                            component: () => import('@/views/pages/Project/Module/Point/UpdatePointContours'),
                          },
                          {
                            path: 'notes',
                            name: 'points.notes',
                            component: () => import('@/views/pages/Project/Module/Point/ShowNotesOfPoint'),
                          },
                          {
                            path: '',
                            name: 'points.show',
                            component: () => import('@/views/pages/Project/Module/Point/ShowPoint'),
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },

  /* OPERATORS */
  {
    path: '/users',
    component: () => import('@/libs/Router/components/InnerRouter'),
    meta: {
      guards: { roles: ['admin'] },
    },
    children: [
      {
        path: '',
        name: 'users.list',
        component: () => import('@/views/pages/Operator/ListOperators'),
      },
      {
        path: 'new',
        name: 'users.new',
        component: () => import('@/views/pages/Operator/CreateOperator'),
      },
      {
        path: ':id',
        component: () => import('@/views/pages/Operator/OperatorFrame'),
        children: [
          {
            path: '',
            name: 'users.show',
            component: () => import('@/views/pages/Operator/ShowOperator'),
          },
          {
            path: 'edit',
            name: 'users.edit',
            component: () => import('@/views/pages/Operator/EditOperator'),
          },
        ],
      },
    ],
  },

  logoutRoute,
  catchallToRootRoute,
];
